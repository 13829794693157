'use strict'

// const service = process.env.VUE_APP_service_clienti || '' // usato solo per installazione su Windows 32bit (senza IIS con reverse proxy)

var config, service
(async () => {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_clienti || process.env.VUE_APP_service_clienti
})()

async function cicleSync() {
  try {
    const response = await fetch(`${service}/clienti/cicleSync`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    return null
  }
}

async function getRunMode() {
  try {
    const response = await fetch(`${service}/clienti/mode`)
    const result = await response.json()
    if (result.status === 404) {
      return defaultRunMode()
    }
    return result
  } catch {
    return defaultRunMode()
  }
}

function defaultRunMode() {
  return {
    modulo: 'CLIENTI',
    locale: '---',
    remote: '---',
    versione: '---',
    health: '---'
  }
}

async function searchClienti(searchText) {
  const response = await fetch(`${service}/clienti/search?flt=${searchText}&cl=true`)
  return await response.json()
}

async function getFornitori() {
  const response = await fetch(`${service}/clienti/fornitori`) // TODO: da rivedere
  return await response.json()
}

async function searchFornitori(searchText) {
  const response = await fetch(`${service}/clienti/search?flt=${searchText}&fr=true`)
  return await response.json()
}

async function getCliente(clienteId) {
  const response = await fetch(`${service}/clienti/${clienteId}`)
  return await response.json()
}

async function creaCliente(cliente) {
  const response = await fetch(`${service}/clienti/create`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(cliente)
  })
  return await response.json()
}

async function getModello() {
  const response = await fetch(`${service}/clienti/modello`)
  return await response.json()
}

async function modificaCliente(cliente) {
  const response = await fetch(`${service}/clienti/${cliente._id}/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(cliente)
  })
  return await response.json()
}

async function getCardFromBarcode(barcode) {
  console.log('clienti--> getCardFromBarcode')
  const response = await fetch(`${service}/clienti/cards/${barcode}`)
  return await response.json()
}

async function getFilteredCustomers(filtri, opzioni) {
  const data = {
    filtri,
    opzioni
  }
  try {
    const response = await fetch(`${service}/clienti/filter`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (error) {
    console.log(error)
    return []
  }
}

export default {
  cicleSync,
  getRunMode,
  searchClienti,
  getFornitori,
  searchFornitori,
  getCliente,
  creaCliente,
  getModello,
  modificaCliente,
  getCardFromBarcode,
  getFilteredCustomers
}
