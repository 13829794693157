'use strict'
import configurazioni from '@/services/configurazioniService.js'
// const service = process.env.VUE_APP_service_prodotti || '' // usato solo per installazione su Windows 32bit (senza IIS con reverse proxy)
var config, service
(async () => {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_prodotti || process.env.VUE_APP_service_prodotti
})()

let bearerToken = ''

const LRU = require('lru-cache')
const options = { max: 500, maxAge: 1000 * 60 * 60 }
const cache = new LRU(options)

function setToken(key) {
  bearerToken = key
}

async function cicleSync() {
  if (cache) {
    cache.reset()
  }
  try {
    const response = await fetch(`${service}/prodotti/cicleSync`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    return null
  }
}

async function getRunMode() {
  try {
    const response = await fetch(`${service}/prodotti/mode`)
    return await response.json()
  } catch {
    return {
      modulo: 'PRODOTTI',
      locale: '---',
      remote: '---',
      versione: '---',
      health: '---'
    }
  }
}

async function getAllProducts() {
  const response = await fetch(`${service}/prodotti`)
  return await response.json()
}

async function getFilteredProducts(filtri, opzioni, versione) {
  const data = {
    filtri,
    opzioni
  }
  try {
    /* eslint-disable */
    const response = await fetch(`${service}/prodotti/${versione ? versione + '/' : '' }filter`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (error) {
    console.log(error)
    return []
  }
}

async function getProdottiByIds(ids) {
  try {
    const response = await fetch(`${service}/prodotti/ids`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(ids)
    })
    const result = await response.json()
    return result.rows.filter(x => !x.error && !x.value.deleted).map(x => x.doc)
  } catch (error) {
    // winston.error(`getProdottiByIds: ${error}`);
    console.log(error)
    return []
  }
}

async function getModello(tipo) {
  const response = await fetch(`${service}/prodotti/modello`)
  return await response.json()
}

async function getProdotto(codice) {
  const response = await fetch(`${service}/prodotti/${codice}`)
  return await response.json()
}

async function searchProdotti(searchText) {
  // prevedere token
  const response = await fetch(`${service}/prodotti/search?flt=${searchText}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken
    }
  })

  // const response = await fetch(`${service}/prodotti/search?flt=${searchText}`)
  return await response.json()
}

async function searchProdottiTC(searchText) {
  const response = await fetch(`${service}/prodotti/search/tc?flt=${searchText}`)
  return await response.json()
}

// Ritorna il documento completo del prodotto
async function getDettaglioProdotto(code) {
  const response = await fetch(`${service}/prodotti/${code}`)
  return await response.json()
}

async function creaProdotto(prodotto) {
  const response = await fetch(`${service}/prodotti/create`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(prodotto)
  })
  return await response.json()
}

async function aggiornaProdotto(prodotto) {
  const id = encodeURIComponent(prodotto.codice)
  const response = await fetch(`${service}/prodotti/${id}/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(prodotto)
  })
  return await response.json()
}

// Ritorna solo alcuni campi del documento utili per la visualizzazione
async function getInfoProdotto(code, listino, variante, cliente, aliquota, barcode, cercaDisattivati) {
  const data = {
    code,
    listino,
    variante: variante || '',
    cliente: cliente || '',
    aliquota,
    barcode, // opzionale, se presente in alternativa al codice prodotto
    cercaDisattivati
  }
  const searchText = JSON.stringify(data)
  const cachedValue = cache.get(searchText)
  if (cachedValue) {
    return cachedValue
  }
  const url = `${service}/prodotti/info/scontrino`

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data })
  })
  const result = await response.json()
  cache.set(searchText, result)

  return result
}

async function getServizi(searchText) {
  let url = `${service}/prodotti/servizi`
  if (searchText) {
    url += `?flt=${searchText}`
  }
  const response = await fetch(url)
  return await response.json()
}

async function getInfoFromBarcode(barcode) {
  console.log('prodotti--> getInfoFromBarcode')
  // const response = await fetch(`${service}/prodotti/barcode/${barcode}`)
  const response = await fetch(`${service}/prodotti/barcode/index/${barcode}`)
  return await response.json()
}

async function getListBarcode(barcode) {
  const response = await fetch(`${service}/prodotti/barcode/list/${barcode}`)
  return await response.json()
}

async function creaProdottoTestBarcode(barcode, prodotto, nomeImport) {
  delete prodotto.barcode
  var data = {
    barcode,
    codice: prodotto.codice,
    nomeImport,
    prodotto
  }
  const response = await fetch(`${service}/prodotti/create/barcode`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function creaProdottiMultipliTestBarcode(prodotti, nomeImport, usaTagliaColore, skipEsistente) {
  var data = {
    prodotti,
    nomeImport,
    usaTagliaColore,
    skipEsistente
  }
  const response = await fetch(`${service}/prodotti/multipli/create/barcode`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function creaBarcodeProdotto() {
  var barcode = await configurazioni.getBarcodeInfo('prodotto')
  const data = {
    prefisso: `${barcode.prefissoInterno}`, // TODO: verificare, prefissoInterno viene utilizzato per generare manualemnte i codici
    ultimoNumero: barcode.ultimoNumero,
    tipo: barcode.tipoCodice
  }
  const response = await fetch(`${service}/prodotti/barcode/create`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  const barcodeCreato = await response.json()
  await configurazioni.setBarcodeInfo('prodotto', 'write', barcodeCreato.ultimoNumero)
  return barcodeCreato
}

async function estraeCategorie(nomeCampoSorgente) {
  try {
    const data = {
      nomeCampoSorgente
    }
    const response = await fetch(`${service}/prodotti/categorie`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    const result = await response.json()
    return result
  } catch (error) {
    console.log(`estraeCategorie: ${error}`)
    return error
  }
}

async function aggiornaGiacenzaMultipla(parametri, prodotti, usaTagliaColore) {
  try {
    const data = {
      parametri,
      prodotti,
      usaTagliaColore
    }
    const response = await fetch(`${service}/prodotti/multipli/giacenza`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return response.json()
  } catch (error) {
    console.log(`aggiornaGiacenzaMultipla: ${error}`)
  }
  return false
}

export default {
  setToken,
  cicleSync,
  getRunMode,
  getAllProducts,
  getFilteredProducts,
  getProdottiByIds,
  getModello,
  getProdotto,
  searchProdotti,
  searchProdottiTC,
  getServizi,
  creaProdotto,
  aggiornaProdotto,
  creaProdottoTestBarcode,
  getInfoProdotto,
  getDettaglioProdotto,
  getInfoFromBarcode,
  getListBarcode,
  creaBarcodeProdotto,
  creaProdottiMultipliTestBarcode,
  estraeCategorie,
  aggiornaGiacenzaMultipla
}
