'use strict'
// const service = process.env.VUE_APP_service_movimenti || '' // usato solo per installazione su Windows 32bit (senza IIS con reverse proxy)
var config, service
(async () => {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_movimenti || process.env.VUE_APP_service_movimenti
})()

async function cicleSync() {
  try {
    const response = await fetch(`${service}/movimenti/cicleSync`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    return null
  }
}

async function getRunMode() {
  try {
    const response = await fetch(`${service}/movimenti/mode`)
    const result = await response.json()
    if (result.status === 404) {
      return defaultRunMode()
    }
    return result
  } catch {
    return defaultRunMode()
  }
}

function defaultRunMode() {
  return {
    modulo: 'MOVIMENTI',
    locale: '---',
    remote: '---',
    versione: '---',
    health: '---'
  }
}

async function getModello(tipo) {
  const response = await fetch(`${service}/movimenti/modello/${tipo}`)
  return await response.json()
}

async function creaMovimentiMultipli(magazzino, parametri, movimenti) {
  try {
    const data = {
      magazzino,
      parametri,
      movimenti
    }
    const response = await fetch(`${service}/movimenti/multipli`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return response.json()
  } catch (error) {
    console.log(error)
    // winston.error(`creaMovimentiMultipli: ${error}`);
  }
  return {}
}

export default {
  cicleSync,
  getRunMode,
  getModello,
  creaMovimentiMultipli
}
