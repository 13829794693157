'use strict'

import errorService from '@/services/errorService'
// const service = process.env.VUE_APP_service_tabelle || '' // usato solo per installazione su Windows 32bit (senza IIS con reverse proxy)
var config, service
(async () => {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_tabelle || process.env.VUE_APP_service_tabelle
})()

async function cicleSync() {
  try {
    const response = await fetch(`${service}/tabelle/cicleSync`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    return null
  }
}

async function getRunMode() {
  try {
    const response = await fetch(`${service}/tabelle/mode`)
    return await response.json()
  } catch {
    return defaultRunMode()
  }
}

/*
async function getRunMode() {
  try {
    const response = await fetch(`${service}/tabelle/mode`)
    const result = await response.json()
    if (!result.status || result.status === 404) {
      return defaultRunMode()
    }
    return result
  } catch {
    return defaultRunMode()
  }
} */

function defaultRunMode() {
  return {
    modulo: 'TABELLE',
    locale: '---',
    remote: '---',
    versione: '---',
    health: '---'
  }
}

/* async function searchTabelle(searchText) {
  const response = await fetch(`/tabelle/search?flt=${searchText}`)
  return await response.json()
}

async function getTabella(tabellaId, tipo) {
  try {
    const response = await fetch(`/tabelle/${tabellaId}${subConf ? '/' + subConf : ''}`)
    return await response.json()
  } catch (error) {
    errorService.onError(error, 'getTabella')
  }
}

async function creaTabella(tabella) {
  const response = await fetch('/tabelle/create', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(tabella)
  })
  return await response.json()
}

async function modificaTabella(tabella) {
  const response = await fetch(`/tabelle/${tabella._id}/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(tabella)
  })
  return await response.json()
} */

async function creaRigaTabella(riga) {
  try {
    const response = await fetch(`${service}/tabelle/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(riga)
    })
    return await response.json()
  } catch (error) {
    errorService.onError(error, 'creaRigaTabella')
  }
}

async function getTabella(tipo) {
  try {
    const response = await fetch(`${service}/tabelle/${tipo}`)
    return await response.json()
  } catch (error) {
    errorService.onError(error, 'getTabella')
  }
}

async function customAggiornaTabacchi() {
  try {
    const response = await fetch(`${service}/tabelle/custom/tabacchi`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    errorService.onError(error, 'customAggiornaTabacchi')
  }
}

export default {
  cicleSync,
  getRunMode,
  creaRigaTabella,
  getTabella,
  customAggiornaTabacchi
}
